import React from 'react';
import '../../App.css';

import { Tabca } from '../Tab';

export default function Services() {
  return <div style={{marginTop:'5%'}}>
  <Tabca/>
  </div>

}
